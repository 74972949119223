// Gatsby supports TypeScript natively!
import React from 'react';
import { Link } from 'gatsby';
import { Router } from '@reach/router';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import ScanResult from '../components/ScanResult';
import Default from '../components/Default';

const ResultPage = () => (
  <Layout>
    <SEO title="Deepware Result" />
    <Router basepath="/result">
      <ScanResult path="/:reportID" />
      <ScanResult path="/:reportID/:anlystToken" />
      <Default path="/" />
    </Router>
  </Layout>
);

export default ResultPage;
